body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: rgb(0,12,35);  */
  background-color: #000819;
 
}

a, u {
  text-decoration: none;
}

select option {
  margin: 40px;
  background: rgba(0, 12, 35, 0.9);  
  color: rgba(255, 255, 255, 0.65);
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
