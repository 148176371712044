@import url('https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,600,700,800');
@import url('https://fonts.googleapis.com/css?family=Raleway');
@import url('https://fonts.googleapis.com/css?family=Space+Mono');



.App {
  text-align: center;
  font-family : 'Space Mono';
 
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.custom-box {
  font-family: "Space Mono" !important;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  display: inline-block;
  padding: 6px 11px;
  width: 45px;
  height: 45px;
  font-size: 36pt;  
  line-height: 1.8;
  color: rgba(255, 255, 255, 0.65);
  background-color: rgba(255, 255, 255, 0.1);
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  -webkit-transition: all .3s;
  transition: all .3s;
  text-transform: none;
}
.custom-input {
  font-family: "Space Mono" !important;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  display: inline-block;
  padding: 6px 11px;
  width: 65%;
  height: 42px;
  font-size: 16px;
  line-height: 1.8;
  color: rgba(255, 255, 255, 0.65);
  background-color: rgba(255, 255, 255, 0.1);
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  -webkit-transition: all .3s;
  transition: all .3s;
  text-transform: none;
}

.custom-select {
  font-family: "Space Mono" !important;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  display: inline-block;
  padding: 6px 11px;
  width: 65%;
  height: 42px;
  font-size: 16px;
  line-height: 1.8;
  color: rgba(255, 255, 255, 0.65);
  background-color: rgba(255, 255, 255, 0.1);
  
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  -webkit-transition: all .3s;
  transition: all .3s;
  text-transform: none;
}

.custom-input:hover {
  border-color: #40a9ff;
  border-right-width: 1px !important;
}

.custom-input:focus {
  border-color: #40a9ff;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  border-right-width: 1px !important;
}

.custom-input-danger {
  font-family: "Poppins" !important;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  display: inline-block;
  padding: 4px 11px;
  width: 65%;
  height: 32px;
  font-size: 14px;
  line-height: 1.5;
  color: #ff4d4f;
  background-color: rgba(255, 255, 255, 0);
  background-image: none;
  border: 1px solid #ff4d4f;
  border-radius: 4px;
  -webkit-transition: all .3s;
  transition: all .3s;
  text-transform: none;
}

.custom-input-danger:hover {
  border-color: #f53d40;
  border-right-width: 1px !important;
}

.custom-input-danger:focus {
  border-color: #e0393c;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  border-right-width: 1px !important;
}

.custom-input-success {
  font-family: "Poppins" !important;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  display: inline-block;
  padding: 4px 11px;
  width: 100%;
  height: 32px;
  font-size: 14px;
  line-height: 1.5;
  color: #3ac55a;
  background-color: rgba(255, 255, 255, 0);
  background-image: none;
  border: 1px solid #3ac55a;
  border-radius: 4px;
  -webkit-transition: all .3s;
  transition: all .3s;
  text-transform: none;
}

.custom-input-success:hover {
  border-color: #46db69;
  border-right-width: 1px !important;
}

.custom-input-success:focus {
  border-color: #2daf4b;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  border-right-width: 1px !important;
}

.line-through {
  text-decoration: line-through;
}

.ant-message-notice-content {
  padding: 10px 16px;
  border-radius: 4px;
box-shadow: 0 4px 12px rgba(0, 0, 0, 0.45) !important;
color: #FFF !important;
font-size: 12px;
font-weight: 100;
letter-spacing: 2px;
text-transform: uppercase;
  background: lighten(#041827, 8%) !important;
  display: inline-block;
  pointer-events: all;
}

.ant-message {
  bottom: 16px !important;
}

.ant-message-notice:first-child {
  /* margin-bottom: -8px; */
  position: absolute !important;
  bottom: 0 !important;
  width: 100% !important;
margin: 0 auto !important;
}

.ant-notification-notice {
  padding: 16px 24px;
  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
background: lighten(#041827, 8%) !important;
color: #fff !important;
  line-height: 1.5;
  position: relative;
  margin-bottom: 16px;
  overflow: hidden;
}
.ant-notification-notice-content {
font-weight: 200;
letter-spacing: 1px;
text-transform: uppercase;
}
.ant-notification-notice-description {
font-size: 12px !important;
}
.ant-notification-notice-message {
font-size: 14px !important;
}

.ant-notification-close-icon {
color: rgba(255, 255, 255, 0.5);
}



.ant-popover-inner {
background-color: #0a2840 !important;
}

.ant-popover-arrow {
background: #0a2840 !important;
}

.ant-popover-message {
color: rgba(255, 255, 255, 0.65) !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
